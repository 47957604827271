// @flow
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
import { User as AuthUser, UserCredential } from 'firebase/auth'
import { Firestore } from 'firebase/firestore'
import { app } from 'firebase-admin'
import { Auth } from 'firebase-admin/auth'
import { Firestore as FireStoreAdmin } from 'firebase-admin/firestore'
import { Storage } from 'firebase-admin/storage'
import { KeywordHouseType } from '../libs/KeyWordsHelper.js'
import { Language, TextKey } from './Antiloop.js'
import { HomeFormGooglePlace } from './GooglePlace.js'
import { CreatePromptRequest, CreatePromptRequestBase } from './JaguarTypes.js'
import { PreferredLoginMethod } from './SignInTypes.js'
import { Functions } from 'firebase-admin/functions'

export type TODO_FIX = any

export type ExternalUserInfo = {
  externalUserId: string
  sourceSystem: 'FASAD' | 'VITEC' | 'VITEC_NEXT'
  externalUserEmail?: string
}

export type CustomClaims = ExternalUserInfo

export type User = BaseObject &
  CreateUserRequest & {
    organizationId?: string
    usedSessions: number
    shouldShowTutorial?: boolean
    externalUserInfo?: ExternalUserInfo
  }

export type CreateOrganizationRequest = {
  domain: string
  name: string
}

export type CustomText = BaseObject & {
  text: string
}

export type Organization = BaseObject &
  CreateOrganizationRequest & {
    logoPath?: string
    lastUserActiveAt?: string
    likedSessions: JaguarSession[]
    customTexts: CustomText[]
    availableModelReferences: 'gazelle' | 'company'
  }

export type Id = string

export interface IdName {
  id: string
  name: string
}

export type CreateUserRequest = {
  _id: string
  name: string
  language?: Language
  email?: string // email can be undefined on anonymous fasad users
  profileImage?: string
  lastActiveAt?: string
  pushToken?: string | null
  orgUserStatus?: OrgUserStatus
  role?: OrgUserRole
}

export enum OrgUserRole {
  FULL = 'FULL',
  LIMITED = 'LIMITED'
}

export type BaseObject = {
  _id: Id
  createdAt: string
}

export type FirebaseUser = AuthUser

export type Mail = {
  from: string // 'anders.back@me.com',
  to: string // 'anders.back@me.com',
  message: {
    subject: string // 'Hello from Firebase!',
    html: string // 'This is an <code>HTML</code> email body.',
  }
}

export type InvitationRequest = {
  name: string
  createdBy: string
  message?: string
  orgId: string
  email: string
  language: Language
  preferredLoginMethod: PreferredLoginMethod
}

export enum OrgUserStatus {
  INVITED = 'INVITED',
  ACTIVE = 'ACTIVE',
  DISABLED = 'DISABLED',
  DELETE = 'DELETE'
}

export type FirebaseAdmin = app.App
export type DB = Firestore

export type ServerOptions = { db: FireStoreAdmin; auth: Auth; storage: Storage; functions: Functions }

export type SignInRequest = { email: string; dryRun?: boolean }

export type AuthError = { code: string; credential: UserCredential; email: any }

export type CreateCustomAuthTokenRequest = {
  id: string
}

export type CustomAuthToken = BaseObject & {
  token: string
}

export type CreateLoginTokenRequest = BaseObject & {
  email: string
  enableSendgrid?: boolean
}

export type LoginWithCodeToken = CreateLoginTokenRequest & {
  code: string
}

export type JaguarRequestForm = CreatePromptRequestBase & {
  googlePlace?: HomeFormGooglePlace
  houseType?: KeywordHouseType
  propertyInfo?: string // 'Small aparment in Stockholm. Balcony, second floor. View over park'
  isValidByServer?: boolean
}

export enum JaguarStyle {
  CONCISE = 'Concise',
  SALESY = 'Salesy',
  DETAILED = 'Detailed',
  EXCLUSIVE = 'Exclusive'
}

export enum JaguarType {
  OVERVIEW = 'overview',
  INTERIOR = 'interior',
  EXTERIOR = 'exterior',
  AREA = 'area',
  BRF = 'brf'
}

export type Thumb = 'up' | 'down'

export type JaguarSession = BaseObject & JaguarSessionRequest & { thumbs?: Thumb; headline?: string }

export type JaguarSessionRequest = {
  userId: string
  request: CreatePromptRequest
  response: string
  status: 'active' | 'stopped' | 'completed' | 'failed'
  extra?: { externalSessionId?: string }
}

export type AdFeedbackRequest = {
  requestId: string
  rating?: number
  comment?: string
  thumbs?: Thumb
}

export type InviteUserRequest = {
  orgId?: string
  name: string
  email: string
  language: string
  createdBy: string
}

export type CreateExternalSessionRequest = {
  objectId: number
  userId: number
  successCallbackUrl: string
  successRedirectUrl?: string
  objectMetaData: {
    address: string | null // Kråkbacken 13
    zipCode: string | null // 117 57
    zipArea: string // Stockholm'
    objectType: string // Hyresrätt - Lokal
    country?: string // SE, DA
    thumbnailUrl: string
  }
  houseType?: KeywordHouseType
  defaultPrompt?: string
  errorCallbackUrl?: string
  errorRedirectUrl?: string
  type?: string // <overview, exterior, interior, area>
}

export type ExternalSession = BaseObject &
  CreateExternalSessionRequest & {
    activeSessions: { [property in JaguarType]: JaguarSession | null } // TODO set [property in JaguarType]
    status?: JaguarSession['status']
    adId?: string
    billedAt?: string
    authenticationToken?: string
  }

export type CreateExternalSessionResponse = {
  redirectUrl: string
  id: string
}

export type PieChartData = Array<{
  textKey: TextKey
  value: number
  backgroundColor: string
}>

export type AdRequest = {
  userId: string
  activeSessions?: { [jaguarType: string]: JaguarSession | null } // TODO set [property in JaguarType]
  extra?: {
    externalSession?: ExternalSession
  }
}

export type Ad = BaseObject & AdRequest
