import * as Yup from 'yup'
import { getFigmaText } from './TextRepository'
import { TextsDeprecated } from '../../../../pure/libs/TextsDeprecated'
import { EmailValidation, RequiredTextValidation } from './ValidationHelper'

export const ValidationSchema = Yup.object().shape({
  name: RequiredTextValidation(),
  email: EmailValidation('email', getFigmaText(TextsDeprecated.onboardingEmailErrorNotValidEmail)),
  message: Yup.string()
})

export const inSelectedOrder = (s) => (a, b) => {
  if (s === a) return -1
  if (s === b) return 1
  return 0
}
