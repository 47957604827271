/* eslint-disable prettier/prettier */
import type Stripe from 'stripe'
import CloudFunction, { StripeCloudFunction } from '../../../../pure/leopard/CloudFunctions.js'
import { CreateJaguarOrderForVitecNextOrderRequest } from '../../../../pure/leopard/CreateJaguarOrderForVitecNextOrderRequest.js'
import { GetEstateForVitecNextOrderRequest } from '../../../../pure/leopard/GetEstatenForVitecNextOrderRequest.js'
import { IsVitecNextProductEnabledRequest } from '../../../../pure/leopard/IsVitecNextProductEnabledRequest.js'
import { IsVitecNextProductEnabledResponse } from '../../../../pure/leopard/IsVitecNextProductEnabledResponse.js'
import { VitecImage } from '../../../../pure/swagger/VitecSEDerivedTypes.js'
import { VitecLandingQueryParams } from '../../../../pure/types/QueryParamTypes.js'
import { DeliverVitecNOCaptionsRequest, PutVitecNOImageResponse, VitecNOOrderQueryParams, VitecNextEstate } from '../../../../pure/types/VitecNODerivedTypes.js'
import { CreateLoginTokenRequest, CustomAuthToken, ExternalSession, InviteUserRequest, LoginWithCodeToken } from '../../../../pure/types/types.js'
import { State } from '../hooks/useAppState.js'
import { callFunction } from './CloudFunctionsApiHandlerHelper.js'

export const deleteAccount = (uid: string, state: State): Promise<void> => callFunction(CloudFunction.deleteAccount, state, uid)

export const createAuthenticationTokenForLoginToken = (req: LoginWithCodeToken, state: State): Promise<CustomAuthToken> => callFunction(CloudFunction.createAuthenticationTokenForLoginToken, state, req)

export const createLoginWithCodeToken = (req: CreateLoginTokenRequest, state: State): Promise<CreateLoginTokenRequest> => callFunction(CloudFunction.createLoginWithCodeToken, state, req)

export const createStripePortalLink = (req, state: State): Promise<{url: string}> => callFunction(StripeCloudFunction.createStripePortalLink as unknown as CloudFunction, state, req)

export const inviteUser = (req: InviteUserRequest, state: State): Promise<unknown> => Promise.resolve() // callFunction(CloudFunction.inviteUser, state, req)

export const processExternalSession = (req: ExternalSession, state: State): Promise<ExternalSession> => callFunction(CloudFunction.processExternalSession, state, req)

export const createSessionForUnpayedSession = (id: string, state: State): Promise<ExternalSession> => callFunction(CloudFunction.createSessionForUnpayedSession, state, id)

export const getStripeSubscriptions = (state: State): Promise<Stripe.Subscription[]> => callFunction(CloudFunction.getStripeSubscriptions, state)

export const getImagesForVitecEstate = (order: VitecLandingQueryParams, state: State): Promise<VitecImage[]> => callFunction(CloudFunction.getImagesForVitecEstate, state, order)

export const createAuthenticationTokenForVitecCustomer = (req: VitecLandingQueryParams, state: State): Promise<string> => callFunction(CloudFunction.createAuthenticationTokenForVitecCustomer, state, req)

export const createAuthenticationTokenForUid = (uid: string, state: State): Promise<string> => callFunction(CloudFunction.createAuthenticationTokenForUid, state, uid)

export const deliverViteNOCaptions = (req: DeliverVitecNOCaptionsRequest, state: State): Promise<PutVitecNOImageResponse> => callFunction(CloudFunction.deliverViteNOCaptions, state, req)

export const createJaguarOrderForVitecNextOrder = (req: CreateJaguarOrderForVitecNextOrderRequest, state: State): Promise<PutVitecNOImageResponse> => callFunction(CloudFunction.createJaguarOrderForVitecNextOrder, state, req)

export const isVitecNextProductEnabled = (req: IsVitecNextProductEnabledRequest, state: State): Promise<IsVitecNextProductEnabledResponse> => callFunction(CloudFunction.isVitecNextProductEnabled, state, req)

export const getEstateForVitecNextOrder = (req: GetEstateForVitecNextOrderRequest, state: State): Promise<VitecNextEstate> => callFunction(CloudFunction.getEstateForVitecNextOrder, state, req)

