export enum Collections {
  USERS = 'users',
  LOGIN_WITH_CODE_TOKEN = 'login_with_code_token',
  CUSTOM_AUTH_TOKENS = 'custom_auth_tokens',
  MAIL = 'mail',
  JAGUAR_SESSIONS = 'jaguar_sessions',
  PRODUCTS = 'products',
  ORGANIZATIONS = 'organizations',
  EXTERNAL_SESSIONS = 'external_sessions',
  ADS = 'ads',
  VITEC_ORDER = 'vitec_order',
  VITEC_NO_ORDER = 'vitec_no_order',
  VITEC_NEXT_ESTATES = 'vitec_next_estates',
  VITEC_NEXT_DOCUMENTS = 'vitec_next_documents',
  FEATURE_FLAGS = 'feature_flags',
  LEOPARD_ORDERS = 'leopard_orders',
  JAGUAR_ESTATES = 'jaguar_estates',

  // Legacy
  UPLOADS = 'uploads',

  // Visma
  VISMA_CORE_CASES = 'visma_core_cases'
}

export default Collections
