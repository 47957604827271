import EventEmitterPkg from 'events'

export enum Events {
  ON_LOGGED_IN = 'ON_LOGGED_IN',
  CREATE_AD_CLICKED = 'CREATE_AD_CLICKED',
  NEW_SERVER_ERROR = 'NEW_SERVER_ERROR',
  NEW_ANALYTICS_EVENT = 'NEW_ANALYTICS_EVENT',
  ON_CREATE_AD_CTA_CLICKED = 'ON_CREATE_AD_CTA_CLICKED',
  ON_ARE_GAZELLE_PRUDCTS_ENABLED_UPDATED = 'ON_ARE_GAZELLE_PRUDCTS_ENABLED_UPDATED'
}

const eventEmitter = new EventEmitterPkg.EventEmitter()

eventEmitter.setMaxListeners(30)

export const upsertListener = (eventName: Events, listener: (...args: any[]) => void) => {
  eventEmitter.removeAllListeners(eventName)
  eventEmitter.on(eventName, listener)
}

export default eventEmitter

export type EventEmitter = typeof eventEmitter
