import { getFeatureFlagsFromUrl } from './libs/Common.js'

export type ConfigType = typeof defaultConfig

type PartialConfig = Partial<typeof defaultConfig>

let env: string | undefined = (import.meta as any).env?.VITE_ENVIRONMENT

if (process.env.NODE_ENV === 'test') env = 'test'
if (!env) env = 'prod'

export const defaultConfig = {
  // Config
  name: 'default',
  appUrl: 'http://localhost:5175',
  b2bUrl: 'https://gazelle-b2b.web.app', // 'https://b2b.gazelle.work'
  jaguarUrl: 'https://legacy---jaguar-wu7ao43iaa-lz.a.run.app',
  faqUrl: 'https://faq-e8d702.webflow.io',
  publlishableStripeApiKey:
    'pk_live_51MvbibHlb1dmBncHwqvwzemq4PtTak0co9BgarM2Q2sysIj8AuyzVspNKyBWGUAml2vOPJ6KCGK4BvNwrgXlIKV500VHRk1Yre',
  sentryDsn: 'https://7ebdc9ea88a244deb112a70ee372ed16@o4505027564339200.ingest.sentry.io/4505027568140288',
  slackWebHookUrl: 'https://hooks.slack.com/services/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',

  // Features
  enableLogFirebaseDBError: false,
  enableLogFirestoreQueryResult: false,
  enableLoggingOfConfig: false,
  enableLoggedInSandbox: false,
  enableDevMenu: false,
  enableTesData: false,
  enableExampleText: false,
  enableErrorMessagesInNotifications: false,
  enableTidio: false,
  enableTestButtons: false,
  enableSendgrid: true,
  enableUploadDeprecated: false,
  enableThumbs: false,
  enableSentry: false,
  enableOffice365Login: true,
  enableAnalyticsEmitter: true,
  enableAnalytics: false,
  enableAIWriter: false,
  enablePremium: false,
  enableKeyWords: false,
  enableServiceUnavilablePage: false,
  enableRemoveAds: false,
  enableSampleData: false,
  enableRemoveUpsell: false, // Used by martin in demos https://trello.com/c/OpHEeSnS/624-remove-upgrade-offer-for-premium-members
  enableLikeAd: false,
  enableFilterOnDomainInOrganizatoinView: true,
  enableOrganizationView: false,
  enableEnglishLanguage: false,
  enableLogUploadRequestId: false,
  enableGooglePlace: true,
  disableExperimentalShortLongDescription: false,

  // Permissions
  enableInviteUsers: true,
  enableDeleteAccount: false,
  enableEditDomains: false
}

export const local: PartialConfig = {
  ...defaultConfig,
  name: 'local',
  enableTesData: true,
  enableAnalytics: false,
  enableErrorMessagesInNotifications: true,
  enableDevMenu: true,
  enableTestButtons: false,
  enablePremium: false,
  enableRemoveAds: true,
  enableLogUploadRequestId: true,
  enableGooglePlace: false
}

const test: PartialConfig = {
  ...defaultConfig,
  name: 'test',
  enableLogFirebaseDBError: false
}

export const prod: PartialConfig = {
  ...defaultConfig,
  appUrl: 'https://app.gazelle.work',
  name: 'prod',
  enableLogFirestoreQueryResult: false,
  enableSentry: true,
  enableLoggingOfConfig: false,
  enableAnalytics: true,
  enableTidio: true
}

export const gazelleUser: PartialConfig = {
  enableEditDomains: true,
  enableDeleteAccount: true,
  enableAIWriter: true,
  enableKeyWords: true,
  enableRemoveAds: true,
  enableLikeAd: false,
  enableFilterOnDomainInOrganizatoinView: false,
  enableOrganizationView: true
}

export const adminUser: PartialConfig = {
  enableInviteUsers: true
}

const configs = { local, prod, test }

let config = configs[env] || prod

config = { ...config, ...getFeatureFlagsFromUrl() }

// eslint-disable-next-line no-console
if (config.enableLoggingOfConfig) console.log('Running app with config', config)

export default config as typeof defaultConfig
