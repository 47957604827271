/* eslint-disable prettier/prettier */
// @flow
import { collection, deleteDoc, doc, getDoc, getDocs, query, where, QueryConstraint, setDoc, addDoc } from 'firebase/firestore'
import { ServerFirebaseUser } from '../../../../pure/types/ServerTypes.js'
import { BaseObject, DB, Id } from '../../../../pure/types/types.js'
import Collections from '../../../../pure/libs/Collections.js'
import { createUniqueId } from '../../../../pure/libs/Common.js'
import { mapQueryResponse, toBaseObject, toUpdatedObject } from '../../../../pure/libs/FirebaseStorageClientBaseHelper.js'
import config from '../Config.js'

export const createObject = <T>(object: T, user: ServerFirebaseUser, collection: string, db: DB): Promise<T> => setItem(collection, { ...toBaseObject(), ...object }, db).then((res) => onSuccess(res, 'createObject', collection)).catch(e => onError(e, 'createObject', collection))

export const addObject = <T>(object: T, collection: string, db: DB): Promise<T> => Promise.resolve(addItem(collection, object, db)).then((res) => onSuccess(res, 'addObject', collection)).catch(e => onError(e, 'addObject', collection))

export const updateObject = <T extends BaseObject>(object: T, collection: string, db: DB, user?: ServerFirebaseUser, ): Promise<T> => setItem(collection, toUpdatedObject(object, user), db).then((res) => onSuccess(res, 'updateObjcet', collection)).catch(e => onError(e, 'updateObject', collection))

export const removeObject = (id: string, path: string, db: DB) => deleteDoc(doc(db, path, id)).then(() => {return}).then((res) => onSuccess(res, 'removeObject', path)).catch(e => onError(e, 'removeObject', path))

export const getAllObjects = <T>(path: Collections, db: DB): Promise<Array<T>> => getDocs(collection(db, path)).then((snapshot) => mapQueryResponse(snapshot)).then((res) => onSuccess(res, 'getAllObjects', path)).catch(e => onError(e, 'getAllObjects', path))

export const getAllObjectsWithUserId = <T>(uid: string, collection: Collections, db: DB): Promise<Array<T>> => getDocs(getAllObjectsWithUserIdQuery(uid, collection, db)).then((snapshot) => mapQueryResponse(snapshot)).then((res) => onSuccess(res, 'getAllObjectsWithOrganizationId', collection)).catch(e => onError(e, 'getAllObjectsWithOrganizationId', collection))

export const getObject = <T extends BaseObject>(_id: Id, collection: string, db: DB): Promise<T | undefined> => getDoc(getObjectQuery(db, collection, _id)).then(res => res.data() as T | undefined).then((res) => onSuccess(res, 'getObject', collection)).catch(e => onError(e, `getObject: ${_id}`, collection))

export const getObjectQuery = (db: DB, path: string, _id: string) => doc(db, path, _id || createUniqueId())

export const getObjectsQuery = (db: DB, path: string) => collection(db, path)

export const getAllUsersForUserQuery = (db: DB, organizationId: string) => query(collection(db, Collections.USERS), where('organizationId', '==', organizationId))

export const getAllObjectsWithUserIdQuery = (uid: string, path: Collections, db: DB, queryContraits: QueryConstraint[] = []) => query(collection(db, path), where('userId', '==', uid), ...queryContraits)

export const getQuery = (path: Collections, db: DB, queryContraits: QueryConstraint[] = []) => query(collection(db, path), ...queryContraits)


const onError = (error: Error, name: string, path: string) => {
  error.message = `${name} ${path} ${error.message}`
  if(config.enableLogFirebaseDBError) console.error(error)
  return Promise.reject(error)
}

const onSuccess = <T>(res: T, fnName: string, path: string) => {
  if (config.enableLogFirestoreQueryResult) console.log('FirebaseStorageClientBaseHelper', 'onSuccess', fnName, path)
  return res
}

// eslint-disable-next-line prettier/prettier
export const setItem = <T extends BaseObject>(path: string | Collections, object: T, db: DB): Promise<T> => setDoc(doc(db, path, object._id), object).then(() => object);
// eslint-disable-next-line prettier/prettier
export const addItem = <T>(path: string | Collections, object: T, db: DB): Promise<T> => addDoc(object as any, collection(db, path)).then(() => object);