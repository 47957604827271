import * as Auth from 'firebase/auth'
import invariant from 'invariant'
import React, { useEffect } from 'react'
import eventEmitter, { Events } from '../../../../pure/libs/EventEmitter'
import { SignInState, SignInSteps, SignInViewProps } from '../../../../pure/types/SignInTypes'
import useAppState from '../hooks/useAppState'
import useIsMobile from '../hooks/useIsMobile'
import useLogin from '../hooks/useLogin'
import useSignInState from '../hooks/useSignInState'
import { auth } from '../libs/Firebase'
import onUnhandledPromiseRejection from '../libs/onUnhandledPromiseRejection'
import { DEFAULT_SIGN_IN_STATE, onAuthStateChanged, SignInPageProps } from '../libs/SignInHelper'
import * as SignInMachineHelper from '../libs/SignInMachine'
import { SignInMachineServices } from '../libs/SignInMachineHelper'
import { AppleAuthProvider, GoogleAuthProvider, MicrosoftAuthProvider } from '../libs/SignInProviders'
import AppContainer from './AppContainer'
import { LoadingPage } from './LoadingPage'
import SignInCode from './SignInCode'
import SignInCodeMobile from './SignInCodeMobile'
import SignInLanding from './SignInLandingExperimental'
import SignInLandingMobile from './SignInLandingMobile'
import { signInWithPopup } from '../libs/FirebaseHelper'

const SignInViewsDesktop = {
  [SignInSteps.LANDING]: SignInLanding,
  [SignInSteps.SIGN_UP]: SignInLanding,
  [SignInSteps.ENTER_CODE]: SignInCode
}

const SignInViewsMobile = {
  [SignInSteps.LANDING]: SignInLandingMobile,
  [SignInSteps.SIGN_UP]: SignInLandingMobile,
  [SignInSteps.ENTER_CODE]: SignInCodeMobile
}

const SignInPage: React.FC<SignInPageProps> = (props) => {
  const isMobile = useIsMobile()
  const Views = isMobile ? SignInViewsMobile : SignInViewsDesktop
  const { state, setState } = useAppState()
  const [isLoading, setIsLoading] = React.useState(false)
  const { signInState, setSignInState } = useSignInState(props.signInState)
  useEffect(() => Auth.onAuthStateChanged(auth, (user) => onAuthStateChanged(user, signInState)), [signInState])

  const loading = useLogin({
    onLogin: props.onLogin,
    onFinally: () => {
      setSignInState(DEFAULT_SIGN_IN_STATE)
      setIsLoading(false)
    }
  })

  if (loading) return <LoadingPage />

  if (!!state.user._id) return <AppContainer />

  const onError = (err) => {
    if (err.code === 'auth/account-exists-with-different-credential')
      return SignInMachineHelper.onAccountExistsWithDifferentCredentialsError(
        err,
        signInState,
        state,
        SignInMachineServices
      )
        .then((signInState) => setSignInState(signInState))
        .catch(onError)
    onUnhandledPromiseRejection(err)
    setSignInState(DEFAULT_SIGN_IN_STATE)
    eventEmitter.emit(Events.NEW_SERVER_ERROR)
  }

  const signInWithAuthProvider = (authProvider: Auth.AuthProvider) =>
    Promise.resolve(setIsLoading(true))
      .then((): unknown => signInWithPopup(authProvider))
      .catch((err) => {
        onError(err)
        setIsLoading(false)
      })

  const signInViewProps: SignInViewProps = {
    isLoading: isLoading,
    signInState: signInState,
    onClickBack: () => {
      switch (signInState.step) {
        default:
          return setSignInState(DEFAULT_SIGN_IN_STATE)
      }
    },
    onTakeMeBack: () => setSignInState(DEFAULT_SIGN_IN_STATE),
    onLoginWithGoogle: () => signInWithAuthProvider(GoogleAuthProvider).catch(onError),
    onLoginWithMicrosoft: () => signInWithAuthProvider(MicrosoftAuthProvider).catch(onError),
    onLoginWithApple: () => signInWithAuthProvider(AppleAuthProvider).catch(onError),
    onPressContinue: (signInState) =>
      Promise.resolve(setIsLoading(true))
        .then(() => SignInMachineHelper.onPressContinue(signInState, { isMobile }, state, SignInMachineServices))
        .then((signInState: SignInState) => setSignInState(signInState))
        .catch(onError)
        .finally(() => setIsLoading(false))
  }

  const component: React.FC<SignInViewProps> = Views[signInViewProps.signInState.step]
  invariant(component, `Cant find Onboarding Step for %s`, signInViewProps.signInState.step)

  return React.createElement(component, signInViewProps)
}

export default SignInPage
